<template>
    <Menu
        as="div"
        class="relative inline-block text-left"
    >
        <div>
            <MenuButton
                class="inline-flex w-full items-center justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-medium leading-5"
                :class="[btnTypeStyles]"
                :disabled="disable"
            >
                <template v-if="showDefaultButton">
                    {{ title }}
                    <NfsIcon
                        icon="plus-small"
                        size="small"
                    />
                </template>
                <slot />
            </MenuButton>
        </div>

        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none lg:w-60">
                <div class="py-1">
                    <MenuItem
                        v-for="option in props?.options"
                        :key="option.title"
                        v-slot="{ active }"
                    >
                        <NuxtLinkLocale
                            v-if="option.link"
                            :to="option.link"
                            :class="[active ? 'bg-neutral-100 text-neutral-900' : 'text-neutral-700', 'block px-4 py-2 text-sm']"
                        >
                            {{ option.title }}
                        </NuxtLinkLocale>
                        <div
                            v-else-if="option.callback"
                            :class="[active ? 'bg-neutral-100 text-neutral-900' : 'text-neutral-700', 'block px-4 py-2 text-sm']"
                            @click="$emit('menuOptionClicked',option)"
                        >
                            {{ option.title }}
                        </div>
                        <div
                            v-else
                            :class="[active ? 'bg-neutral-100 text-neutral-900' : 'text-neutral-700', 'block px-4 py-2 text-sm']"
                            @click="$emit('menuOptionClicked',option)"
                        >
                            {{ option.title }}
                        </div>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script lang="ts" setup>
import {
    Menu, MenuButton, MenuItem, MenuItems
} from '@headlessui/vue';

type btnTypes = 'brand' | 'transparent' | 'disable';
const props = defineProps({
    title: {
        type: String,
        default: 'menu'
    },
    disable: {
        type: Boolean,
        default: false
    },
    options: {
        type: Array as PropType<{title: string, link: string, callback?: boolean}[]>,
        default: () => ([{
            title: 'title 1',
            link: '#'
        }])
    },
    showDefaultButton: {
        type: Boolean,
        default: true
    },
    menuBtnType: {
        type: String as PropType<btnTypes>,
        default: 'brand'
    }
});

defineEmits<{
  (e: 'menuOptionClicked'): void
}>();

const colorClasses: Record<btnTypes, string> = {
    brand: 'bg-brand-300 hover:bg-brand-400 shadow-sm',
    transparent: 'bg-transparent',
    disable: 'bg-brand-300 shadow-sm opacity-50'
};
const btnTypeStyles = computed(() => colorClasses[props?.menuBtnType]);
</script>
